import React from 'react';
import { Link } from 'gatsby';

export const ProjectCard = ({
  name, description, to, image,
}) => (
  <Link
    to={to}
    className="w-11/12 my-2 mx-auto bg-white overflow-hidden rounded-lg shadow-lg hover:shadow-xl transform-gpu transition-transform hover:scale-105"
  >
    {image}

    <div className="leading-tight p-2 md:p-4">
      <h2 className="text-lg">
        {name}
      </h2>

      <p className="mt-2 text-gray-600 text-sm">
        {description}
      </p>
    </div>
  </Link>
);
