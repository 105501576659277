import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import SEO from "../../components/SEO";
import { ProjectCard } from "../../components/ProjectCard";

const InlineLink = ({ children, ...rest }) => (
  <Link
    className="border-b-4 border-green-400 hover:bg-green-200"
    {...rest}
  >
    {children}
  </Link>
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: {fields: frontmatter___title, order: ASC}) {
        nodes {
          slug
          frontmatter {
            emoji
            title
            description
            technologies
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 530
                  height: 280
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `);

  return (
    <main className="site">
      <SEO
        title="William Di Luigi - Projects"
        description="Some projects that I've built in my free time."
      />

      <Navbar />

      <div className="site-content">
        <div className="w-full gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-16 text-xl text-gray-800 leading-normal">
          {data.allMdx.nodes.map(({ frontmatter, slug }) => (
            <ProjectCard
              to={`/${slug}`}
              description={frontmatter.technologies}
              name={`${frontmatter.emoji} ${frontmatter.title}`}
              image={(
                <GatsbyImage
                  alt=""
                  objectFit="contain"
                  imgStyle={{objectFit: 'contain'}}
                  image={getImage(frontmatter.image)}
                />
              )}
            />
          ))}
        </div>
      </div>

      <Footer />
    </main>
  )
}

export default IndexPage
